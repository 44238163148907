<template>
  <transition v-if="!loading" name="fade">
      <div id="wrapper">
        <nav :class="'navbar bg-transparent p-0 pb-2'">
          <div class="container-fluid">
            <div class="navbar-brand bars me-2 mt-2" @click="this.$router.push({name: 'Home'})">
                <i class="ph-arrow-left"></i>
            </div>

            <img :src="require('../assets/img/logo-orange.png')" alt="" style="height: 75px;">  

            <span class="navbar-left">
                <div class="navbar-brand">
                <span style="display: -webkit-inline-box;">
                    <h4 style="margin-top: 0.2rem">
                    <i class="ph-bell-ringing text-light me-3 nav-icon circle p-2 bg-default"></i>
                    </h4>
                </span>
                </div>      
            </span>

            <div class="divider mt-4 d-none"></div>
          </div>
        </nav>

        <div class="body mb-4" style="min-height: auto">
          <div class="container" style="padding: 1rem 2rem 0 2rem">
            
            <div class="row"> 
              <form id="form" action="#" enctype="multipart/form-data">

                <div class="col-lg-12 col-md-12">
                  <h1 class="text-orange text-center fw-bold p-0" style="font-size: 1.8rem">{{ no_drugs }} of your products require a presciption.</h1>
                  
                  <status v-model:status_data=status_data></status>
                  
                  <div class="d-flex mt-3">
                      <div class="d-block mx-auto text-center me-4">
                            <img class="circle p-3" :src="require('../assets/img/icons8-file-upload-64.png')" alt="" @click="this.$router.push({name: 'Prescription'})">
                            <figcaption class="mt-2 ms-2 me-2">
                                <h6 class="mt-3 mb-0 text-dark">Upload your Prescription</h6>
                                <small class="mb-0 fst-italic">If you've already gotten a valid prescription</small>
                            </figcaption>    
                      </div>
                      
                      <div class="d-block mx-auto text-center">
                            <img class="circle p-3" :src="require('../assets/img/icons8-request-64.png')" alt="" @click="this.$router.push({name: 'CartToDoctor'})">
                            <figcaption class="mt-2 ms-2 me-2">
                                <h6 class="mt-3 mb-0 text-dark">Request Prescription</h6>
                                <small class="mb-0 fst-italic">Get a prescription from a doctor</small>
                            </figcaption>      
                      </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>      
      </div>
  </transition>
</template>

<style lang="scss" scoped>
  @import '../assets/scss/layout/_navbar.scss';

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>

<script>
  import Status from '../components/Status'
  import { ToWords } from 'to-words'

  export default {
    name: 'Prescription',
    components: {
      Status
    },   
    data() {
      return {
        status_data: {
          "success": false,
          "success_message": null,
          "error": false,
          "error_message": null   
        },
        prescription: [],
        prescription_drugs: null,
        no_drugs: null
      }
    },  
    watch: {
      user: function() {
        this.ifNotLoggedIn()
      },
      cart_stock: function () {
        this.getPrescriptionDrugs()
      }
    },
    computed: {
      user () {
        return this.$store.state.user
      },
      loading () {
        return this.$store.state.loading
      },
      cart_stock () {
        return this.$store.state.cart_stock
      }      
    },    
    mounted() {
      this.$store.dispatch("getUser")
      this.$store.dispatch("getStockInCart")
    },
    methods: {
      ifNotLoggedIn() {
        if( !this.user.name) {
          this.$router.push({name: 'Login'});
        } 
      },
      getPrescriptionDrugs() {
        Object.keys(this.cart_stock).forEach(element => {
          if(this.cart_stock[element].prescription) {
            this.prescription_drugs = element
            this.prescription.push(this.cart_stock[element])
          }
        });

        const toWords = new ToWords();
        this.no_drugs = toWords.convert(this.cart_stock.length)
      },
    }    
  }
</script>
